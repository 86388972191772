<template>
    <div class="col">
    <div class="col-sm-12 col-md-12 mt-3">
    <div class="dataTables_paginate paging_simple_numbers">
        <ul class="pagination justify-content-end py-0 my-0 gap-2">
        <li
            class="paginate_button page-item previous"
            :class="pagination.prevPageUrl == null ? 'disabled' : ''"
        >
            <button
            type="button"
            :class="pagination.prevPageUrl == null ? 'disabled' : ''"
            @click="$emit('next' , pagination.prevPageUrl)"
            class="btn bg-light text-dark"
            >
            <i class="fas fa-arrow-left"></i>
            </button>
        </li>

        <li
            class="paginate_button page-item next"
            :class="pagination.nextPageUrl == null ? 'disabled' : ''"
        >
            <button
            type="button"
            :class="pagination.nextPageUrl == null ? 'disabled' : ''"
            @click="$emit('next' , pagination.nextPageUrl)"
            aria-controls="DataTables_Table_0"
            data-dt-idx="8"
            tabindex="0"
            class="btn bg-light text-dark"
            >
            <i class="fas fa-arrow-right"></i>
            </button>
        </li>
        </ul>
    </div>
    <div class="col-sm-12 col-md-6">
        <div
          class="dataTables_info"
          id="DataTables_Table_0_info"
          role="status"
          aria-live="polite"
        >
          Showing {{pagination.from}} to {{ pagination.to ?? 0 }} of {{ pagination.total }} entries
        </div>
      </div>
    </div>
</div>
</template>

<script>
export default {

props:["paginationProp"],
data(){
 return {
    pagination:{
        prevPageUrl:null,
        nextPageUrl:null,
        total:0,
        from:0,
        to:0,
    }
 }
},
watch:{
    paginationProp:function(newVal){
    this.configurePagination(newVal);
    }
},
methods:{
configurePagination(pagination){
this.pagination.nextPageUrl = pagination?.next_page_url;
this.pagination.prevPageUrl = pagination?.prev_page_url;
this.pagination.total = pagination?.total;
this.pagination.from = pagination?.from;
this.pagination.to = pagination?.to;
}


},
    
}
</script>
