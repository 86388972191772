<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import pagination from "@/views/pages/admin/calendar/reuseableComponents/pagination.vue";
import _ from "lodash";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import excelParser from "@/mixins/excel-parser.js";

/**
 * Add-product component
 */
export default {
  page: {
    title: "Shifts",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    pagination,
    DateRangePicker,
  },
  data() {
    return {
      title: "Shift Per Employee",
      employees: [],
      extraFilters: {
        length: 10,
        search: null,
        from: null,
        to: null,
      },
      date_range: {
        startDate: null,
        endDate: null,
      },

      paginationData: null,
      currentPage: 1,
      loading: false,
      sortDesc: false,
      location_id: "showAll",
      locations: [],

      fields: [
        {
          key: "user.name",
          label: "Employee",
          sortable: true,
          class: "text-capitalize",
        },
        {
          label: "Phone#",
          key: "user.phone",
          sortable: true,
        },
        {
          key: "user.email",
          label: "Email",
          sortable: true,
        },

        "location",
        "work_area",
        "shift_accepted",
        "shift_released",
      ],
    };
  },

  middleware: "authentication",

  watch: {
    "date_range.endDate": {
      handler: function (new_data, old_data) {
        if (new_data) {
          this.extraFilters.from = this.formatDateYMD(
            this.date_range.startDate
          );
          this.extraFilters.to = this.formatDateYMD(this.date_range.endDate);
          this.fetchEmployee();
        }
      },
    },
    location_id: {
      handler: function (new_id, pre_id) {
        if (new_id != "showAll") {
          this.extraFilters.location_id = new_id;
          this.fetchEmployee();
        } else {
          this.extraFilters.location_id = null;
          this.fetchEmployee();
        }
      },
    },
  },

  filters: {
    date(val) {
      return val ? moment(val).format("YYYY-MM-DD") : "";
    },
  },

  methods: {
    fetchEmployee(url) {
      url = url ?? "/shift/per-employee";
      this.loading = true;
      this.$axios
        .get(url, { params: this.extraFilters })
        .then((response) => {
          let data = response.data.payload;
          this.employees = data.data;
          this.paginationData = data;
          this.loading = false;
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(() => (this.loading = false));
    },

    arrayToString(data) {
      let stringArray = data.map((item) => {
        return item.name;
      });
      let string = stringArray.join(" , ").toString();
      return string;
    },

    showWorkArea(areaRoles) {
      let areaRolesArray = [];
      areaRoles.forEach((element) => {
        let found = areaRolesArray.findIndex(
          (workAreaName) => workAreaName == element.area.name
        );
        if (found < 0) {
          areaRolesArray.push(element.area.name);
        }
      });
      return areaRolesArray.join(" , ").toString();
    },

    getTimeline(timeline, status) {
      return timeline.filter((item) => item.status == status).length;
    },

    updateRecordsBySearching: _.debounce(function (search) {
      this.extraFilters.search = search.target.value;
      this.fetchEmployee();
    }, 400),

    fetchLocations() {
      this.$axios
        .get("/restaurant/location/index")
        .then((response) => {
          this.locations = response.data.payload;
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(function () {});
    },
    clearFilters() {
      (this.date_range = {
        startDate: null,
        endDate: null,
      }),
        (this.location_id = "showAll");
      this.extraFilters.from = this.formatDateYMD(
        moment().startOf("month").toDate()
      );
      this.extraFilters.to = this.formatDateYMD(
        moment().endOf("month").toDate()
      );
      this.fetchEmployee();
    },

    exportCSV() {
      this.extraFilters.length = this.paginationData.total,
      this.$axios
        .get("/shift/per-employee", { params: this.extraFilters })
        .then((response) => {
          let shiftsListing = response.data.payload.data;
          let buildData = shiftsListing.map((item) => {
            return {
              "Employee Name": item.user.name,
              "Phone": item.user.phone,
              "Email": item.user.email,
              "Location": this.arrayToString(item.locations),
              "Work Area": this.arrayToString(item.area_roles),
              "Shift Accepted":  this.getTimeline(item.shift_time_lines, "Accepted"),
              "Shift Released": this.getTimeline(item.shift_time_lines, "Released"),
            };
          });
          excelParser.methods.exportDataFromJSON(buildData, "shifts-per-employee", null);
        })
        .catch((error) => {
          // this.handleErrorResponse(error.response, "error");
          console.log(error);
        });
    },
  },

  mounted() {
    this.fetchLocations();
    // this.extraFilters.from = this.formatDateYMD(moment().startOf("month").toDate());
    // this.extraFilters.to = this.formatDateYMD(moment().endOf("month").toDate());
    this.fetchEmployee();
  },
};
</script>

<template>
  <Layout :dynamicClass="'container-fluid'">
    <div id="section">
      <div id="place_content" class="row">
        <div id="modal-parent">
          <div class="row">
            <div class="col-md-6">
              <PageHeader :title="title" />
            </div>
            <div class="col-md-6 direction-rtl px-4">
              <button
                class="primary-button btn text-white"
                @click="clearFilters"
              >
                <i class="mdi mdi-filter-variant px-1"></i>Clear Filters
              </button>
            </div>
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body row">
                  <div class="col-md-5">
                    <label>Filter by Location</label>
                    <select
                      v-model="location_id"
                      id="emp_job_type"
                      class="form-select"
                    >
                      <option value="showAll">Show All</option>
                      <option
                        :value="location.id"
                        v-for="(location, index) in locations"
                        :key="index"
                      >
                        {{ location.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-7">
                    <label>Select Date</label>
                    <div class="d-sm-flex justify-content-between">
                      <div class="col-sm-6 col-12 me-3 mb-3">
                        <date-range-picker
                          class="bg-light w-100 p-1"
                          ref="picker"
                          :opens="'right'"
                          :locale-data="{ format: 'mm/dd/yyyy' }"
                          :singleDatePicker="false"
                          :timePicker="false"
                          :timePicker24Hour="false"
                          :showWeekNumbers="true"
                          :showDropdowns="true"
                          :autoApply="false"
                          v-model="date_range"
                        >
                          <template
                            v-slot:input="picker"
                            style="min-width: 350px"
                          >
                            {{ picker.startDate | date }} -
                            {{ picker.endDate | date }}
                          </template>
                        </date-range-picker>
                      </div>
                      <div class="col-sm-6 col-12">
                        <div class="d-block me-sm-4 mb-sm-0 mb-3">
                          <input
                            type="text"
                            placeholder="Search"
                            v-model="extraFilters.search"
                            v-on:input="updateRecordsBySearching"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div>
                  <b-button class="gradientColor border-0  px-4 float-end mx-2" @click="exportCSV();"><i
                      class="uil uil-export align-middle me-1"
                  ></i> Export as CSV</b-button>
                </div>
                <div class="card-body" id="listing_parent_card">
                  <div class="table-responsive mb-0">
                    <b-table
                      table-class="table table-centered datatable mt-3 table-bordered"
                      thead-tr-class="bg-transparent"
                      :items="employees"
                      :fields="fields"
                      responsive="sm"
                      :per-page="0"
                      :current-page="currentPage"
                      :sort-desc.sync="sortDesc"
                      show-empty
                      :busy="loading"
                    >
                      <template #table-busy>
                        <APILoader
                          v-if="loading"
                          :loading="loading"
                          class="align-middle"
                        ></APILoader>
                      </template>

                      <template v-slot:cell(location)="data">
                        <p class="text-capitalize">
                          {{ arrayToString(data.item.locations) }}
                        </p>
                      </template>

                      <template v-slot:cell(shift_accepted)="data">
                        <p class="text-center">
                          {{
                            getTimeline(data.item.shift_time_lines, "Accepted")
                          }}
                        </p>
                      </template>

                      <template v-slot:cell(shift_released)="data">
                        <p class="text-center">
                          {{
                            getTimeline(data.item.shift_time_lines, "Released")
                          }}
                        </p>
                      </template>

                      <template v-slot:cell(name)="data">
                        <span>
                          <img
                            :src="
                              data.item.user.avatar == null
                                ? data.item.user.avatar_path
                                : assets_url + data.item.user.avatar
                            "
                            class="avatar-xs rounded-circle me-2"
                          />
                        </span>
                        <span class="text-capitalize">{{
                          data.item.user.name
                        }}</span>
                      </template>

                      <template v-slot:cell(work_area)="data">
                        <p class="text-capitalize">
                          {{ showWorkArea(data.item.area_roles) }}
                        </p>
                      </template>
                    </b-table>
                  </div>
                  <!-- pagination -->
                  <div class="row">
                    <pagination
                      :paginationProp="paginationData"
                      @next="fetchEmployee"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
